import React from 'react';
import Layout from '../components/layout';
import RolunkHero from '../components/rolunk/rolunk-hero';
import RolunkTeam from '../components/rolunk/rolunk-team';

const RolunkPage = () => {
  return (
    <Layout
      seo={{
        title: 'A Fortély-csapat',
        description: 'A Fortély-csapat'
      }}
    >
      <RolunkHero />
      <RolunkTeam />
    </Layout>
  );
};

export default RolunkPage;
