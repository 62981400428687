import React from 'react';
import clsx from 'clsx';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const ProfilePhoto = ({ profile }) => {
  const { sari, csabi, akos, evo } = useStaticQuery(graphql`
    query {
      sari: file(relativePath: { regex: "/team_sari.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      csabi: file(relativePath: { regex: "/team_csabi.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      akos: file(relativePath: { regex: "/team_akos.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      evo: file(relativePath: { regex: "/team_evo.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const profiles = {
    sari: {
      img: sari,
      icon: '/icons/statistics.svg',
      alt: 'Sári',
      iconPosition: 'left-0 lg:-left-6 -rotate-24'
    },
    csabi: {
      img: csabi,
      icon: '/icons/calculator.svg',
      alt: 'Csabi',
      iconPosition: 'right-0 lg:-right-6 rotate-24'
    },
    akos: {
      img: akos,
      icon: '/icons/map.svg',
      alt: 'Ákos',
      iconPosition: 'left-0 lg:-left-6 -rotate-24'
    },
    evo: {
      img: evo,
      icon: '/icons/speech.svg',
      alt: 'Evo',
      iconPosition: 'right-0 lg:-right-6 rotate-24'
    }
  };

  const { alt, img, icon, iconPosition } = profiles[profile];

  return (
    <div className="relative w-56 mx-auto md:w-full md:h-auto md:px-8 md:mx-4 lg:px-0 lg:max-w-md">
      <Img
        className="w-56 h-56 rounded-full md:w-full md:h-auto md:rounded"
        alt={alt}
        fluid={img.childImageSharp.fluid}
      />
      <img
        className={clsx(
          'absolute bottom-0 w-20 h-20 transform md:-bottom-6 lg:w-24 lg:h-24',
          iconPosition
        )}
        src={icon}
        alt={alt}
      />
    </div>
  );
};

export default ProfilePhoto;
