import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const RolunkHero = () => {
  const { team } = useStaticQuery(graphql`
    query {
      team: file(relativePath: { regex: "/team_hero.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2560) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className="pt-20">
      <Img fluid={team.childImageSharp.fluid} />
      <h2 className="text-center uppercase py-10">A Fortély-csapat</h2>
    </section>
  );
};

export default RolunkHero;
