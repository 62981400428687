import React from 'react';
import ProfilePhoto from './profile-photo';
import Button from '../layout/button';

const RolunkTeam = () => {
  return (
    <section>
      <div className="container">
        <div className="px-4 py-8 lg:py-16 md:flex md:flex-row-reverse">
          <div className="md:w-1/2 md:flex md:items-center md:justify-center">
            <ProfilePhoto profile="sari" />
          </div>
          <div className="md:w-1/2 max-w-md mx-auto">
            <h2 className="pt-4 uppercase">Sári</h2>
            <a
              href="mailto:sari@fortelysator.hu"
              className="text-base text-primary hover:underline"
            >
              sari@fortelysator.hu
            </a>
            <p className="mt-6">
              A Fortély-sátor ötletének egyik kitalálója vagyok, tanárként az én
              területem a{' '}
              <span className="font-bold">
                szobáink oktatási-pedagógiai hátterének biztosítása.{' '}
              </span>
              Emellett a kreatív munkában is részt veszek, segítek az új
              szobáink fejlesztésében.
            </p>
            <p className="pt-6">
              Mindig jó érzés a játékosok pozitív visszajelzéseit hallani
              egy-egy kitelepülés után, megerősítve azt, hogy a játék sikert
              visz a játékosoknak.
            </p>
          </div>
        </div>
        <div className="px-4 py-8 lg:py-16 md:flex">
          <div className="md:w-1/2 md:flex md:items-center md:justify-center">
            <ProfilePhoto profile="csabi" />
          </div>
          <div className="md:w-1/2 max-w-md mx-auto">
            <h2 className="pt-4 uppercase">Csabi</h2>
            <a
              href="mailto:csabi@fortelysator.hu"
              className="text-base text-primary hover:underline"
            >
              csabi@fortelysator.hu
            </a>
            <p className="mt-6">
              A kezdetek óta a Fortély-sátor csapatának tagja vagyok, alapvetően{' '}
              <span className="font-bold">
                a pénzügyekért és a pályázatokért felelek,{' '}
              </span>
              természetesen az új szobák fejlesztésén és tesztelésén is
              dolgozom.
            </p>
            <p className="pt-6">
              Nagyon szeretek kitelepülésekre járni és beszélgetni a fiatalokkal
              az élményről, amit a szoba nyújtott nekik, és a jövőbeli
              terveikről.
            </p>
          </div>
        </div>
        <div className="px-4 py-8 lg:py-16 md:flex md:flex-row-reverse">
          <div className="md:w-1/2 md:flex md:items-center md:justify-center">
            <ProfilePhoto profile="akos" />
          </div>
          <div className="md:w-1/2 max-w-md mx-auto">
            <h2 className="pt-4 uppercase">Ákos</h2>
            <a
              href="mailto:akos@fortelysator.hu"
              className="text-base text-primary hover:underline"
            >
              akos@fortelysator.hu
            </a>
            <p className="mt-6">
              Az egyik ötletgazda vagyok, és{' '}
              <span className="font-bold">
                a játékelemek mechanikájáért, kivitelezéséért,
                szállíthatóságáért felelek{' '}
              </span>
              az Alapítványban.
            </p>
            <p>
              Ezen kívül, mint mindannyian, nagyon szeretek a kreatív dolgokban
              részt venni, mint például egy új szoba kitalálásában, vagy
              szeretem, ha valami váratlan problémát kell hirtelen megoldanunk.
            </p>
          </div>
        </div>
        <div className="px-4 py-8 lg:py-16 md:flex">
          <div className="md:w-1/2 md:flex md:items-center md:justify-center">
            <ProfilePhoto profile="evo" />
          </div>
          <div className="md:w-1/2 max-w-md mx-auto">
            <h2 className="pt-4 uppercase">Evo</h2>
            <a
              href="mailto:evo@fortelysator.hu"
              className="text-base text-primary hover:underline"
            >
              evo@fortelysator.hu
            </a>
            <p className="mt-6">
              Én vagyok az első olyan tag, aki menet közben csatlakozott a
              csapathoz.{' '}
              <span className="font-bold">
                Szeretek odafigyelni arra, hogy a csapat dinamikája rendben
                legyen.{' '}
              </span>
              Egy csoport működésénél elkerülhetetlen, hogy konfliktus alakuljon
              ki néha a tagok között, ilyenkor felelősségemnek érzem, hogy elég
              időt és energiát szenteljünk ennek feloldására. Mindemellett
              nagyon élvezem a kreatív munkákat és a kitelepüléseket is.
            </p>
          </div>
        </div>
      </div>
      <div className="container text-center py-20">
        <Button variant="outlined" to="/kapcsolat">
          Lépj kapcsolatba velünk!
        </Button>
      </div>
    </section>
  );
};

export default RolunkTeam;
